<template>
	<div class="add-page">
		<vanTitle :title="state.type"></vanTitle>
		<section  class="page-from">
            <div class="item">
                <div class="tage">姓名</div>
                <div class="content">
                    <input type="text" placeholder="请输入您的姓名" class="ipt" v-model="state.params.name">
                </div>
            </div>
            <div class="item">
                <div class="tage">收货电话</div>
                <div class="content">
                    <input type="number" placeholder="请输入您的收货电话" class="ipt" v-model="state.params.phone">
                </div>
            </div>
            <div class="item">
                <div class="tage">所在地区</div>
                <div class="content" @click="bindPop">
                    <input type="text" placeholder="请选择您的所在地区" class="ipt" readonly :value="state.params.cityarea">
                </div>
                <i class="icon-right"></i>
            </div>
            <div class="item">
                <div class="tage">详细地址</div>
                <div class="content">
                    <input type="text" placeholder="请输入您的详细地址" class="ipt" v-model="state.params.address">
                </div>
            </div>
        </section>
        <!--选择省市区-->
        <van-popup v-model:show="state.showArea" position="bottom" :style="{ height: '40%' }">
            <van-area :area-list="state.areaList" @confirm="onAreaConfirm" @cancel="bindPop" :value="state.code" />
        </van-popup>
        <div class="big-btn" @click="saveAddress">保存地址</div>
	</div>
</template>

<script>
import AreaInfo from "../../common/area.js";
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { sensorTrack, getGeoLocation } from '../../utils/util.js';

export default {
    name: "add_edit_address",
    setup() {
        const { proxy } = getCurrentInstance();
        const $router = useRouter();
        const $route = useRoute();
        const state = reactive({
            params:{
                id:'0',
                name:'',//姓名
                phone:'',//电话
                cityarea:'',//省市区拼接 例如：湖北省/十堰市/张湾区
                address:'',//详细地址
                province_id:'',//省
                city_id:'',//市
                area_id:'',//区
                default: '0',//非默认地址
            },
            addressData:[],
            type: $route.query.name,//title
            showArea: false,//省市区弹窗
            areaList: AreaInfo, //引用地区信息
            code: '',
            fromOrder: false,
            department: 0,
            isLoading:false, // 是否正在提交
        })

        onMounted(() => {
            console.log('Component is mounted!');
            if($route.query.id){
                state.params.id = $route.query.id;
                getData();//获取数据
            } else {
                getLocation();
            }
            if($route.query.fromOrder) {
                state.fromOrder = $route.query.fromOrder !== 'false';
            }
            if('department' in $route.query) {
                state.department = $route.query.department;
            }
        })

        // 获取地址信息
        const getData = () => {
            proxy.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: "加载中..."
			});
            proxy.$http.get("/api/address/info",{
                    params: {
                        id: state.params.id
                    }
                })
                .then(res => {
                    proxy.$toast.clear();
                    const body = res.data.data;

                    if(res.data.code == '200'){
                        state.params = body;
                        state.code = body.area_id.toString();
                        state.params.cityarea = body.original_city_area;
                        console.log(state.code);
                    } else {
                        proxy.$toast(body.message);
                    }
                })
                .catch(error => console.log(error))
        }


        // 展示隐藏地区pop
        const bindPop = () => {
            state.showArea = !state.showArea;
        }

        //地区选择
        const onAreaConfirm = (val) => {
            state.showArea = false;
            state.params.province_id = val[0].code || val[0].id;
            state.params.city_id = val[1].code || val[1].id;
            state.params.area_id = val[2].code || val[2].id;
            state.params.cityarea = val[0].name + '/' + val[1].name + '/' + val[2].name;
        }

        // 创建编辑地址
        const saveAddress = () => {
            if(state.isLoading){
                return;
            };
            proxy.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "加载中..."
            }); 
            let reg = /^1[0-9]{10}$/;
            if(state.params.name == ''){
                proxy.$toast("请输入姓名～");
                return false
            }
            if(state.params.phone == ''){
                proxy.$toast("请输入手机号～");
                return false
            }
            if(!reg.test(state.params.phone)){
                proxy.$toast("手机号格式错误～");
                return false
            }
            if(state.params.cityarea == ''){
                proxy.$toast("请选择所在地区～");
                return false
            }
            if(state.params.address == ''){
                proxy.$toast("请输入详细地址～");
                return false
            } 
            state.isLoading=true;
            proxy.$http.post("/api/address/create",state.params)
                .then(res => {
                    state.isLoading=false;
                    console.log("创建编辑地址===>", res.data)
                    const body = res.data;
                    proxy.$toast.clear();

                    if(res.data.code == '200'){
                        state.fromOrder ? sensorTrack({
                            eventName: 'HuaTuEducation_m_htxd_AddUserInfo',
                            params: {
                                user_name: state.params.name,
                                phone: state.params.phone,
                                province: state.params.cityarea.split('/')[0],
                                city: state.params.cityarea.split('/')[1],
                                county: state.params.cityarea.split('/')[2],
                                address: state.params.address,
                                business_unit: state.department,
                                exam_type: '',
                                $url: window.location.href
                            }
                        }).then(res => {
                            $router.go(-1);
                        }) : $router.go(-1);
                    } else {
                        proxy.$toast(res.data.message[0]);
                    }
                })
                .catch(error =>{ 
                    console.log(error)
                    state.isLoading=false;
                })
        }

        const getLocation = () => {
            proxy.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "加载中..."
            })
            getGeoLocation().then(({ addressComponent, formattedAddress }) => {
                proxy.$toast.clear();
                console.log(addressComponent);
                console.log(formattedAddress);
                let address = {
                    'province': addressComponent.province,
                    'city': addressComponent.city == '' ? addressComponent.province : addressComponent.city,
                    'county': addressComponent.district
                },
                addressArr = [];
                for(let key in address) {
                    addressArr.push(getAddressWithValue(key, address[key]));
                }
                onAreaConfirm(addressArr);
                // state.params.address = address.province + address.city + address.county + addressComponent.township;
                state.code = addressComponent.adcode
            })
            .catch(err => {
                proxy.$toast.clear();
                console.log('err:', err);
                if(err === -1) { // AMap加载未完成或者加载失败
                    console.log('AMap加载未完成或者加载失败');
                }
                console.log('获取定位失败');
                // proxy.$toast(err.message || '获取定位失败');
            });
        }

        /**
         * 根据城市名称返回对应id和name
         * 参数params：
         * type: province/city/country
         * value: province/city/country对应的名称
         * 返回值return
         * data: 对象，{id: 地区编码, name: 地区名称}
        */
        const getAddressWithValue = (type, value) => {
            let address = state.areaList[`${type}_list`];
            for (const key in address) {
                if (Object.hasOwnProperty.call(address, key)) {
                    if(value === address[key]) {
                        return {
                            id: key,
                            name: address[key]
                        }
                    }
                }
            }
        }

        return {
            state,
            bindPop,
            onAreaConfirm,
            saveAddress
        }
    },

    methods: {}
};
</script>

<style scoped lang="scss">
.add-page {
	height: 100%;
	background: #F8F9FA;
    font-size: 14px;
	.page-from{
        margin: 7px 15px;
        padding: 0 15px;
        background: #ffffff;
        border-radius: 4px;
        .item{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 48px;
            box-sizing: border-box;
            border-bottom: 1px solid #E6E6E6;
            font-size: 15px;
            color: #333333;
            position: relative;
            .tage{
                width: 82px;
                font-weight: bold;
            }
            .content{
                height: 100%;
                flex: 1;
                .ipt{
                    height: 100%;
                    width: 100%;
                    background: none;
                }
            }
            .icon-right{
                display: block;
                width: 14px;
                height: 14px;
                background: url(../../assets/icons/icon-right.png) center/100%,100% no-repeat;
                position: absolute;
                top: 18px;
                right: 4px;
            }
        }
        .item:last-child{
            border: none;
        }
    }
    .big-btn{
        margin-top: 50px;
    }
}
</style>
